import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Contact() {
  const [discordNames, setDiscordNames] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);

  useEffect(() => {
    // Fetch contact info and set state
    axios.get("/api/contactinfo")
        .then((response) => {
          setDiscordNames(response.data.discord);
          setEmailAddress(response.data.emailAddress);
        })
        .catch((error) => {
          console.error('Failed to fetch contact info:', error);
        });
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied name to Clipboard", {
        position: "top-center",
        autoClose: 2000,
      });
    });
  };

  return (
      <Layout>
        <Helmet>
          <title>Elry - Contact Me</title>
          <meta name="name" content="Elry's Homepage" />
          <meta
              name="description"
              content="Homepage from Kevin Pflugradt, or Elry. A C# & Unity Developer."
          />
        </Helmet>
        <div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Contact Me" />
            <div className="row">
              <div className="col-lg-6">
                <div className="mi-contact-info">
                  <ToastContainer />
                  {discordNames.length > 0 && (
                      <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.MessageSquare />
                    </span>
                        <div className="mi-contact-infoblock-content">
                          <h6>Discord</h6>
                          {discordNames.map((discord) => (
                              <p
                                  key={discord}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => copyToClipboard(discord)}
                              >
                                {discord}
                              </p>
                          ))}
                        </div>
                      </div>
                  )}
                  {emailAddress.length > 0 && (
                      <div className="mi-contact-infoblock">
                    <span className="mi-contact-infoblock-icon">
                      <Icon.Mail />
                    </span>
                        <div className="mi-contact-infoblock-content">
                          <h6>Email</h6>
                          {emailAddress.map((email) => (
                              <p key={email}>
                                <a href={`mailto:${email}`}>{email}</a>
                              </p>
                          ))}
                        </div>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
  );
}

export default Contact;
