import React, {useState, useEffect} from "react";
import axios from 'axios';
import { SiAnilist, SiDiscord, SiGithub, SiLinkedin } from '@icons-pack/react-simple-icons';

function Socialicons(props){
  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    axios.get('/api/information')
      .then(response =>{
        setSocialLinks(response.data.socialLinks);
      })
  }, [])

  return (
    <ul className={props.bordered ? 'mi-socialicons mi-socialicons-bordered' : 'mi-socialicons'}>
      {!socialLinks.anilist ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.anilist}>
          <SiAnilist/>
        </a>
      </li>}
      {!socialLinks.discord ? null : <li>
        <a rel="noopener noreferrer" href={socialLinks.discord}>
          <SiDiscord/>
        </a>
      </li>}
      {!socialLinks.github ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.github}>
          <SiGithub/>
        </a>
      </li>}
      {!socialLinks.linkedin ? null : <li>
        <a rel="noopener noreferrer" target="_blank" href={socialLinks.linkedin}>
          <SiLinkedin/>
        </a>
      </li>}
    </ul>
  );
}

export default Socialicons;
