import React, { useEffect, useState } from "react";
import Blog from './Blog';
import { useLocation, useNavigate } from 'react-router-dom';

function BlogsView({ blogs }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(location.state?.showBanner || false);

  useEffect(() => {
    // If the banner is shown, set a timer to hide it after 10 seconds
    if (showBanner) {
      const timer = setTimeout(() => {
        setShowBanner(false);
        // Clear the banner state in the URL location to prevent it from showing up again on reload
        navigate('/blogs', { state: { showBanner: false }, replace: true });
      }, 6000); // 6 seconds

      // Clean up the timer if the component unmounts or user navigates
      return () => clearTimeout(timer);
    }
  }, [showBanner, navigate]);

  // Clear banner state on any other navigation
  useEffect(() => {
    const handleNavigation = () => {
      if (showBanner) {
        setShowBanner(false);
        navigate('/blogs', { state: { showBanner: false }, replace: true });
      }
    };

    window.addEventListener('popstate', handleNavigation);
    return () => window.removeEventListener('popstate', handleNavigation);
  }, [navigate, showBanner]);

  return (
      <div className="mi-blogs-view mi-section mi-padding-top-min mi-padding-bottom">
        <div className="container">
          {showBanner && (
              <div
                  className="alert alert-warning"
                  style={{
                    marginBottom: '20px',
                    backgroundColor: '#2c0d0d', // Dark background
                    color: '#fff', // Light text
                    border: '1px solid #612222', // Dark border for contrast
                  }}
              >
                <strong>Oops!</strong> Couldn't find that blog post. If you think this is a mistake, feel free to <a href="/contact" style={{ color: '#ec8f4c' }}>contact me</a> !
              </div>
          )}

          <div className="row mt-30-reverse">
            {blogs.map(blog => (
                <div className="col-lg-6 col-md-6 col-12 mt-30" key={blog.id}>
                  <Blog data={blog} />
                </div>
            ))}
          </div>
        </div>
      </div>
  );
}

export default BlogsView;
